export default function buildMakeLazyLoading () {
	return function makeLazyLoading () {

		/**
		 * The observer for lazyloaded images.
		 *
		 * @var {IntersectionObserver}
		 */
		const observer = new IntersectionObserver( lazyload );

		// Observe images.
		document.querySelectorAll( '.js-lazyload' ).forEach( image => observer.observe( image ) );

		/**
		 * Lazyloads images within range.
		 *
		 *
		 * @param {object} events
		 */
		function lazyload ( events ) {
			events.forEach( ({ isIntersecting: ready, target: image }) => {
				if ( ! ready || ! image.dataset.src ) return;

				image.setAttribute( 'src', image.dataset.src );
				image.removeAttribute( 'data-src' );

				observer.unobserve( image );
			});
		}
	};
}
