import domReady from '@roots/sage/client/dom-ready';

const initializeLikes = () => {
  const likesButton = document.querySelector('.likes-button');
  if (!likesButton) return;

  const likesCount = likesButton.querySelector('.likes-count');
  const postId = likesButton.dataset.postId;
  const cookieKey = `lombardy_liked_${postId}`;

  const hasLiked = () => {
    return localStorage.getItem(cookieKey) === 'true';
  };

  const markAsLiked = () => {
    localStorage.setItem(cookieKey, 'true');
    likesButton.classList.add('liked');
  };

  const handleLike = async () => {
    if (hasLiked()) return;

    try {
      const response = await fetch(`/wp-admin/admin-ajax.php?action=lombardy_like_article&post_id=${postId}`);
      const data = await response.json();

      if (data.success) {
        likesCount.textContent = data.data.likes;
        markAsLiked();
      }
    } catch (error) {
      console.error('Error liking article:', error);
    }
  };

  // Check if already liked
  if (hasLiked()) {
    likesButton.classList.add('liked');
  }

  // Add click handler
  likesButton.addEventListener('click', handleLike);
};

domReady(() => {
  initializeLikes();
});
