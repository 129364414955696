import { debounce } from 'lodash';

export default function buildMakeCalcAppHeight () {
	return function makeCalcAppHeight () {

		/**
		 * Updates the page height to be used on the SCSS.
		 * IOS Safari have problems with 100VH calcs
		 */

		const _isIOSDevice = !! navigator.platform.match( /iPhone|iPod|iPad/ );

		if ( _isIOSDevice ) {

			const calcAppHeight = () => {
				const doc = document.documentElement;
				doc.style.setProperty( '--app-height', `${window.innerHeight}px` );
			};
			calcAppHeight();

			window.addEventListener( 'resize', debounce( () => calcAppHeight(), 100 ) );
		}
	};
}
