import { lenis } from "@scripts/lenis";
import { makeScrollLock, makeCalcAppHeight } from "@scripts/helpers";

/**
 * Initializes and runs the Lenis animation frame.
 */
function initializeHeader() {
  /**
	 * The Lenis instance.
	 *
	 * @var {object}
	 */
	// const lenis = new Lenis();

  /**
	 * The scroll lock instance.
	 *
	 * @var {object}
	 */
	const scrollLock = makeScrollLock();

  /**
	 * Update :root with the viewport height
	 *
	 * @var {object}
	 */
	makeCalcAppHeight();

  const headerContainer = document.querySelector('.core-component-header');

  const mobileMenuToggler = headerContainer.querySelector('.mobile-menu-toggler');

  mobileMenuToggler.addEventListener( 'click', () => {
    headerContainer.classList.toggle( 'mobile-menu-active' );
    mobileMenuToggler.classList.toggle( 'active' );
    headerContainer.querySelector( '.js-mobile-menu' ).classList.toggle( 'active' );
    scrollLock[ mobileMenuToggler.classList.contains( 'active' ) ? 'lock' : 'unlock' ]();
    lenis[ mobileMenuToggler.classList.contains( 'active' ) ? 'stop' : 'start' ]();
    headerContainer.querySelector( '.js-mobile-menu' ).setAttribute( 'data-lenis-prevent', mobileMenuToggler.classList.contains( 'active' ) );
  });

  const desktopMediaQuery = window.matchMedia( '(min-width: 1024px)' );

  desktopMediaQuery.addEventListener( 'change', (event) => {
    if ( event.matches ) {
      headerContainer.classList.add( '!bg-black/20' );
      lenis.start();
    } else {
      headerContainer.classList.remove( '!bg-black/20' );
    }
  });
}

// Export the header function
export { initializeHeader };
