export default function buildMakeScrollLock () {
	return function makeScrollLock ({
		isLocked = false,
	} = {}) {

		/**
		 * The last scroll position.
		 *
		 * @var {number}
		 */
		let lastScrollPosition = -1;

		// Lock upon start.
		if ( isLocked ) lock();

		// Return.
		return Object.freeze({
			isLocked: () => isLocked,
			getLastScrollPosition: () => lastScrollPosition,
			lock,
			unlock,
		});

		/**
		 * Locks the scroll.
		 *
		 */
		function lock () {
			lastScrollPosition = window.scrollY;

			document.body.style.top = `-${lastScrollPosition}px`;
			document.body.classList.add( 'scroll-lock' );

			isLocked = true;
		}

		/**
		 * Unlocks the scroll.
		 *
		 */
		function unlock () {
			document.body.classList.remove( 'scroll-lock' );
			document.body.style.removeProperty( 'top' );

			window.scrollTo( 0, lastScrollPosition );

			isLocked = false;
		}
	};
}
