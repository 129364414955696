import domReady from '@roots/sage/client/dom-ready';
import { setupAppearAnimations } from './animations';
import { initializeLenis } from './lenis';
import { initializeHeader } from './components/header';
import { makeLazyLoading } from './helpers';
import './likes';

/**
 * Application entrypoint
 */
domReady(async () => {
  // ...
  makeLazyLoading();
  setupAppearAnimations();
  initializeLenis();
  initializeHeader();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
