import Lenis from 'lenis';

let lenis;

/**
 * Initializes and runs the Lenis animation frame.
 */
function initializeLenis() {
    lenis = new Lenis();

    /**
     * Calls the request animation frame with Lenis
     * @param {DOMHighResTimeStamp} time - The timestamp of the current frame.
     */
    function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);
}

// Export the initialize function
export { initializeLenis, lenis };
