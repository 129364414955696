import buildMakeLazyLoading from "./lazyloading";
import buildMakeScrollLock from "@scripts/helpers/scroll-lock";
import buildMakeCalcAppHeight from './vh-fix';

const makeLazyLoading = buildMakeLazyLoading();
const makeScrollLock = buildMakeScrollLock();
const makeCalcAppHeight = buildMakeCalcAppHeight();

export default Object.freeze({
  makeLazyLoading,
  makeScrollLock,
  makeCalcAppHeight: buildMakeCalcAppHeight(),
});

export { makeLazyLoading, makeScrollLock, makeCalcAppHeight };
